module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/dsgncode/Sites/np-portfolio","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.3,"once":false,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicola Giuia Pernice Portfolio","short_name":"Studio Pernice","start_url":"/","background_color":"#3A3A3B","theme_color":"#FC00BD","display":"standalone","icons":[{"src":"./src/images/favicons/favicon.ico","sizes":"16x16","type":"image/ico"},{"src":"./src/images/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./src/images/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./src/images/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/images/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/images/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"./src/images/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"./src/images/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"injectPageProps":true,"layout":"/Users/dsgncode/Sites/np-portfolio/src/components/Layout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
