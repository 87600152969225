import ProjectDetail from "../../../../src/components/ProjectDetail";
import Heading from "../../../../src/components/Heading";
import { DeviceFrameset } from "../../../../src/components/DeviceFrameSet/DeviceFrameset";
import { ParallaxRouteUpdate } from "../../../../src/components/parallax-route-update";
import { Parallax, ParallaxBanner, useParallaxController } from "react-scroll-parallax";
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Slider from "react-slick";
import RatingPieChart from "../../../../src/components/charts/RatingPieChart";
import HorizontalBarChart from "../../../../src/components/charts/HorizontalBarChart";
import LineChart from "../../../../src/components/charts/LineChart";
import * as React from 'react';
export default {
  ProjectDetail,
  Heading,
  DeviceFrameset,
  ParallaxRouteUpdate,
  Parallax,
  ParallaxBanner,
  useParallaxController,
  Gallery,
  Item,
  Slider,
  RatingPieChart,
  HorizontalBarChart,
  LineChart,
  React
};