import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Heading from "../../../../src/components/Heading";
import ProjectDetail from "../../../../src/components/ProjectDetail";
import Video from "../../../../src/components/Video";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  StaticImage,
  Heading,
  ProjectDetail,
  Video,
  React
};