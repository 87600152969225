import { Gallery, Item } from 'react-photoswipe-gallery';
import ProjectDetail from "../../../../src/components/ProjectDetail";
import Heading from "../../../../src/components/Heading";
import * as React from 'react';
export default {
  Gallery,
  Item,
  ProjectDetail,
  Heading,
  React
};